<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <h2>Statistique HVE de vos exploitants</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h4>Option A</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col cols="12">
            <HveModuleChart
              v-if="modulesA"
              :modules-a="modulesA"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h4>Option B</h4>
            <b-row>
              <b-col cols="6">
                <b-card>
                  <h5>Choix de l’option de biodiversité</h5>
                  <div v-if="bioOptions">
                    <vue-apex-charts
                      type="donut"
                      height="300"
                      :series="hveChartSeries"
                      :options="donutChartOptions"
                    />
                  </div>
                  <template v-else>
                    Aucunes des exploitations ne valident l'option de biodiversité
                  </template>
                </b-card>
              </b-col>
              <b-col cols="6">
                <DistributionChart
                  v-if="distributionIndicator.measures.length > 1"
                  :indicator="distributionIndicator"
                  style="height: 90%;"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-row>
          <IaaFilter />
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BCard
} from "bootstrap-vue";
import { mapState, mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@/../themeConfig";
import HveModuleChart from "@/components/iaa/HveModuleChart.vue";
import IaaFilter from "@/components/iaa/IaaFilter.vue";
import DistributionChart from "@/components/iaa/DistributionChart.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    HveModuleChart,
    IaaFilter,
    DistributionChart
  },
  computed: {
    ...mapState("iaa", ["iaaData"]),
    ...mapGetters("iaa", ["modulesA", "optionB", "cooperatives"]),
    donutChartOptions() {
      return {
        dataLabels: {
          enabled: false
        },
        labels: ["Prairie permanentes", "Part de la SAU en IAE"],
        legend: {
          show: true,
          formatter(seriesName, opts) {
            return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
          },
          position: "bottom",
          horizontalAlign: "left"
        },
        colors: [$themeColors.info, $themeColors.primary],
        plotOptions: {
          pie: {
            donut: {
              size: "75%"
            }
          }
        },
        stroke: {
          show: false
        }
      };
    },
    hveChartSeries() {
      if (this.optionB) {
        return [this.optionB.pp_count, this.optionB.sau_iae_count];
      }

      return null;
    },
    bioOptions() {
      return this.optionB.pp_count > 0 || this.optionB.sau_iae_count > 0;
    },
    distributionIndicator() {
      return {
        measures: this.optionB.intrants_within_turnover
      };
    }
  }

};
</script>
