<template>
  <b-card>
    <h5>Répartition par module</h5>
    <template v-if="isShown()">
      <e-charts
        :options="moduleChartData"
        theme="theme-color"
      />
    </template>
    <template v-else>
      Données non renseignées.
    </template>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import theme from "@core/components/charts/echart/theme.json";
import { $themeColors } from "@/../themeConfig";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    BCard,
    ECharts
  },
  props: {
    modulesA: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      moduleChartData: {
        grid: {
          width: "80%",
          top: 30,
          left: 28,
          bottom: 48
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          left: 0
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0
          }
        }
      }
    };
  },
  watch: {
    modulesA() {
      this.formatModulesA();
    }
  },
  created() {
    this.formatModulesA();
  },
  methods: {
    formatModulesA() {
      const passedData = [];
      const failedData = [];

      // format store data for the chart options

      this.modulesA.forEach(module => {
        let passedCount = 0;
        module.farm_notes.forEach(note => {
          if (note >= 10) {
            passedCount += 1;
          }
        });

        const failedCount = module.farm_notes.length - passedCount;

        passedData.push([module.name, passedCount]);
        failedData.push([module.name, failedCount]);
      });

      // pass formated data to chart options

      this.moduleChartData = {
        ...this.moduleChartData,
        yAxis: {
          max: this.modulesA[0].farm_notes.length,
          type: "value",
          splitLine: { show: false }
        },
        series: [
          {
            type: "bar",
            name: "Non certifiable (note < 10)",
            stack: "option_a",
            itemStyle: {
              color: $themeColors.warning
            },
            data: failedData
          },
          {
            type: "bar",
            name: "Certifiable (note ≥ 10)",
            stack: "option_a",
            barMaxWidth: "40%",
            barMinWidth: "20px",
            itemStyle: {
              color: $themeColors.success
            },
            data: passedData
          }
        ]
      };
    },
    isShown() {
      let farmNotes = [];
      this.modulesA.forEach(module => {
        farmNotes = farmNotes.concat(module.farm_notes);
      });

      return farmNotes.length > 0;
    }
  }
};
</script>
